import { createSlice } from "@reduxjs/toolkit";
import { ProjectsWorkOrders } from "../../../types";
import { EntryListView, MealBreakDay, TimeEntryForm } from "../../types";
import {
  getEmptyTimeEntry,
  getProjectWorkOrder,
  getValueFromSelect,
} from "../../utils/timesUtils";
import { newUuid } from "../../../SharedModule/utils/uuid";
import {
  getDateFromISOFormat,
  isSameDay,
} from "../../../SharedModule/utils/dateUtils";
import { billableIds } from "../../../SharedModule/utils/constants";

type TimesState = {
  hasPageError: boolean;
  projectList: ProjectsWorkOrders[];
  isAdmin: boolean;
  isListView: boolean;
  date: Date;
  user: string;
  timeEntriesList: EntryListView[];
  timeEntriesSheetView: any[];
  summary: {
    total: string;
    billable: string;
    nonBillable: string;
    internal: string;
    timeOff: string;
  };
  entryList: TimeEntryForm[];
  entryListInvalid: TimeEntryForm[];
  isLoading: boolean;
  isSubmitted: boolean;
  holidays: string[];
  scheduledHours: number;
  missingTime: {
    missingTime: boolean;
    hours: number;
    startDate: string;
    endDate: string;
  };
  missingTimeMealBreak: MealBreakDay[];
};

const initialState: TimesState = {
  hasPageError: false,
  projectList: [],
  isAdmin: false,
  isListView: true,
  date: new Date(),
  user: "",
  timeEntriesList: [],
  timeEntriesSheetView: [],
  summary: {
    total: "—",
    billable: "—",
    nonBillable: "—",
    internal: "—",
    timeOff: "—",
  },
  entryList: [
    getEmptyTimeEntry(new Date()),
    getEmptyTimeEntry(new Date()),
    getEmptyTimeEntry(new Date()),
    getEmptyTimeEntry(new Date()),
    getEmptyTimeEntry(new Date()),
  ],
  entryListInvalid: [],
  isLoading: true,
  isSubmitted: false,
  holidays: [],
  scheduledHours: 8,
  missingTime: {
    missingTime: false,
    hours: 0,
    startDate: "",
    endDate: "",
  },
  missingTimeMealBreak: [],
};

export const timesSlice = createSlice({
  name: "times",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSubmitted: (state, action) => {
      state.isSubmitted = action.payload;
    },
    loadTimesInfo: (state, action) => {
      state.missingTime = action.payload.missingTime.data;
      state.missingTimeMealBreak = action.payload.missingTimeMealBreak.data;
      state.projectList = action.payload.projectList;
      // error for new user without price level
      if (action.payload.timeEntriesInfo.error) {
        state.hasPageError = true;
        state.timeEntriesList = initialState.timeEntriesList;
        state.entryList = initialState.entryList;
        state.timeEntriesSheetView = initialState.timeEntriesSheetView;
        state.summary = initialState.summary;
        state.scheduledHours = initialState.scheduledHours;
        state.holidays = initialState.holidays;
      } else {
        state.hasPageError = false;
        // if has content in same day or has invalid entries, clean entry list
        state.timeEntriesList = action.payload.timeEntriesInfo.data.listView;
        let hasPageContent: boolean =
          state.timeEntriesList.length > 0 &&
          state.timeEntriesList.some((entry) =>
            isSameDay(entry.entryDate, action.payload.pageDate)
          );
        // if page has content, do not show time entries
        if (hasPageContent) {
          state.entryList = [];
        }

        if (state.entryListInvalid.length > 0) {
          // add invalid entries and then clen array
          state.entryList = state.entryListInvalid;
          state.entryListInvalid = [];
          state.isSubmitted = true;
        }
        // complete to 5 entry list
        if (!hasPageContent) {
          for (let total = state.entryList.length; total < 5; total++) {
            state.entryList.push(getEmptyTimeEntry(action.payload.pageDate));
          }
        }

        state.timeEntriesSheetView =
          action.payload.timeEntriesInfo.data.sheetView;

        // sheet view doesn't have projectStatus because is the same as entityStatus (diff in list view)
        // add this propert to help function in timeUtils.ts => iconDeleteEnabled
        state.timeEntriesSheetView.forEach((element) => {
          element.projectStatus = element.entityStatus;
        });

        // add this logic, when opens modal to add/edit entry in sheet view get corrects date
        if (
          state.timeEntriesSheetView &&
          state.timeEntriesSheetView.length > 0
        ) {
          for (let key in state.timeEntriesSheetView[0].dailyDetails) {
            if (state.timeEntriesSheetView[0].dailyDetails[key].length > 0) {
              state.timeEntriesSheetView[0].dailyDetails[key].forEach(
                (element) => {
                  element.timeEntries.forEach((timeEntry) => {
                    timeEntry.entryDate = getDateFromISOFormat(
                      timeEntry.entryDate
                    );
                  });
                }
              );
            }
          }
        }

        state.summary = action.payload.timeEntriesInfo.data.summary;
        state.scheduledHours =
          action.payload.timeEntriesInfo.data.user.scheduledHours;
        state.holidays = action.payload.timeEntriesInfo.data.user.holidays;
      }
      state.isLoading = false;
    },
    changeListView: (state, action) => {
      state.isListView = action.payload;
    },
    updateEntry: (state, action) => {
      let foundIndex = state.entryList.findIndex(
        (x) => x.entryId === action.payload.entryState.entryId
      );
      state.entryList[foundIndex] = action.payload.entryState;
    },
    addInvalidEntriesFromEndpoint: (state, action) => {
      let aux: TimeEntryForm;

      action.payload.list.forEach((element) => {
        aux = {
          entryId: newUuid(),
          entryCanEdit: true,
          entryCanDelete: true,
          entryDate: element.timeEntry.entryDate,
          entryDateValid: true,
          entryProjectWorkOrder: getProjectWorkOrder(
            state.projectList,
            element.timeEntry.entityId
          ),
          entryProjectWorkOrderValid: true,
          entryHours: element.timeEntry.hours,
          entryHoursValid: true,
          entryTaskType: getValueFromSelect(
            action.payload.meta.taskTypes,
            element.timeEntry.taskTypeId
          ),
          entryTaskTypeValid: true,
          entryDescription: element.timeEntry.description,
          entryIsBillable: billableIds.includes(
            element.timeEntry.timeEntryTypeId.toString().toLowerCase()
          ),
          entryNonBillableReason: billableIds.includes(
            element.timeEntry.timeEntryTypeId.toString().toLowerCase()
          )
            ? null
            : getValueFromSelect(
                action.payload.meta.nonBillableReasons,
                element.timeEntry.nonBillableReasonId
              ),
          entryNonBillableReasonValid: true,
          // ASUITE11-2700: set valid true if price error, to show again message
          isEntryValid: action.payload.priceError,
          isEntryTouched: true,
        };
        state.entryListInvalid.push(aux);
      });
    },
    addEntry: (state, action) => {
      state.entryList.unshift(getEmptyTimeEntry(action.payload));
    },
    addInvalidEntry: (state, action) => {
      state.entryListInvalid.unshift(action.payload.entry);
    },
    removeValidEntry: (state, action) => {
      state.entryList = state.entryList.filter(
        (entry) => entry.entryId !== action.payload.entry.entryId
      );
    },
    resetEntries: (state, action) => {
      if (action.payload.hasContent) {
        state.entryList = [];
      } else {
        state.entryList = [
          getEmptyTimeEntry(action.payload.pageDate),
          getEmptyTimeEntry(action.payload.pageDate),
          getEmptyTimeEntry(action.payload.pageDate),
          getEmptyTimeEntry(action.payload.pageDate),
          getEmptyTimeEntry(action.payload.pageDate),
        ];
      }
    },
    resetInvalidEntries: (state) => {
      state.entryListInvalid = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setSubmitted,
  loadTimesInfo,
  changeListView,
  updateEntry,
  addInvalidEntriesFromEndpoint,
  addEntry,
  addInvalidEntry,
  removeValidEntry,
  resetEntries,
  resetInvalidEntries,
} = timesSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.times.isLoading;
};

export const HasPageError = (state: any) => {
  return state.times.hasPageError;
};

export const IsSubmitted = (state: any) => {
  return state.times.isSubmitted;
};

export const IsListView = (state: any) => {
  return state.times.isListView;
};

export const ProjectList = (state: any) => {
  return state.times.projectList;
};

export const MissingTime = (state: any) => {
  return state.times.missingTime;
};

export const MissingTimeMealBreak = (state: any) => {
  return state.times.missingTimeMealBreak;
};

export const ScheduledHours = (state: any) => {
  return state.times.scheduledHours;
};

// To load select grouped by
export const SelectProjectOrWorkOrder = (state: any) => {
  return [
    {
      label: "Work Orders",
      options: state.times.projectList?.filter((item) => {
        if (item.categoryId === 3) {
          return item;
        }
      }),
    },
    {
      label: "Projects",
      options: state.times.projectList?.filter((item) => {
        if (item.categoryId !== 3) {
          return item;
        }
      }),
    },
  ];
};

export const Summary = (state: any) => {
  return state.times.summary;
};

export const TimeEntriesList = (state: any) => {
  return state.times.timeEntriesList;
};

export const TimeEntriesSheetView = (state: any) => {
  return state.times.timeEntriesSheetView;
};

export const Holidays = (state: any) => {
  return state.times.holidays;
};

export const EntryList = (state: any) => {
  return state.times.entryList;
};

export default timesSlice.reducer;
