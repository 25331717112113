import { createSlice } from "@reduxjs/toolkit";
import { SelectItem } from "../../../types";
import { getIsoFormatStringWithTimeZone, getLastWeekDay } from "../../../SharedModule/utils/dateUtils";

type TimesQueryState = {
  keepFilters: boolean;
  showAdditionalFilters: boolean;
  filters: {
    startDate: string;
    endDate: string;
    costCenter: Array<any> | null;
    client: Array<any> | null;
    project: Array<any> | null;
    projectManager: Array<any> | null;
    user: Array<any> | null;
    includeInactiveUsers: boolean;
    taskType: Array<SelectItem> | null;
    country: Array<any> | null;
    hourType: {
      billableHours: boolean;
      nonBillableHours: boolean;
      internalHours: boolean;
      // financedHours: boolean;
    };
    nonBillableReasons: Array<SelectItem> | null;
    timeOff: {
      paidTimeOff: boolean;
      unpaidTimeOff: boolean;
    };
    approvalStatus: {
      pendingHours: boolean;
      approvedHours: boolean;
      lockedHours: boolean;
    };
  };
};

const today = new Date();

const initialState: TimesQueryState = {
  keepFilters: false,
  showAdditionalFilters: false,
  filters: {
    startDate: getIsoFormatStringWithTimeZone(getLastWeekDay(7, today)),
    endDate: getIsoFormatStringWithTimeZone(getLastWeekDay(1, today)),
    costCenter: null,
    client: null,
    project: null,
    projectManager: null,
    user: null,
    includeInactiveUsers: false,
    taskType: null,
    country: null,
    hourType: {
      billableHours: true,
      nonBillableHours: true,
      internalHours: true,
      // financedHours: true
    },
    nonBillableReasons: null,
    timeOff: {
      paidTimeOff: true,
      unpaidTimeOff: false,
    },
    approvalStatus: {
      pendingHours: true,
      approvedHours: true,
      lockedHours: true,
    },
  },
};

export const timesQuerySlice = createSlice({
  name: "timesQuery",
  initialState,
  reducers: {
    keepFilters: (state, action) => {
      state.keepFilters = action.payload;
    },
    setAdditionalFilters: (state, action) => {
      state.showAdditionalFilters = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilters, resetFilters, keepFilters, setAdditionalFilters } =
  timesQuerySlice.actions;

export const IsShowAdditionalFilters = (state: any) => {
  return state.timesQuery.showAdditionalFilters;
};

export const FiltersTouched = (state: any) => {
  // check box filters, should be differents from false. Because is true si sending info to BE (not confuse with default values)
  let filtersTouched: any = {
    costCenter: state.timesQuery.filters.costCenter !== null,
    client: state.timesQuery.filters.client !== null,
    project: state.timesQuery.filters.project !== null,
    projectManager: state.timesQuery.filters.projectManager !== null,
    user: state.timesQuery.filters.user !== null,
    taskType: state.timesQuery.filters.taskType !== null,
    country: state.timesQuery.filters.country !== null,
    hourType:
      state.timesQuery.filters.hourType.billableHours !== true ||
      state.timesQuery.filters.hourType.nonBillableHours !== true ||
      state.timesQuery.filters.hourType.internalHours !== true,
      // state.timesQuery.filters.hourType.financedHours !== true,
    nonBillableReasons: state.timesQuery.filters.nonBillableReasons !== null,
    timeOff:
      state.timesQuery.filters.timeOff.paidTimeOff !== true ||
      state.timesQuery.filters.timeOff.unpaidTimeOff !== false,
    approvalStatus:
      state.timesQuery.filters.approvalStatus.pendingHours !== true ||
      state.timesQuery.filters.approvalStatus.approvedHours !== true ||
      state.timesQuery.filters.approvalStatus.lockedHours !== true,
  };
  return filtersTouched;
};

export const SelectFilters = (state: any) => {
  return state.timesQuery.keepFilters
    ? state.timesQuery.filters
    : initialState.filters;
};

export const GetKeepFiltersFlag = (state: any) => {
  return state.timesQuery.keepFilters;
};

export const GetFilters = (state: any) => {
  return state.timesQuery.filters;
};

export const SelectInitialStateFilters = () => {
  return initialState.filters;
};

export default timesQuerySlice.reducer;
