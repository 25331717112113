import { DateTime } from "luxon";

export default function Footer(props) {
  let actualYear = DateTime.now().get("year");

  return (
    <div
      className={`d-flex justify-content-between mb-3 footer ${
        props.isReport ? "footer-report" : ""
      }`}
    >
      <div className="mt-3">Version 1.1</div>
      <div className="mt-3">©{actualYear} ITX Corp.</div>
    </div>
  );
}
